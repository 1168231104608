import React, {useEffect, useState} from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { Button, Input } from 'src/components/common';
import { routes } from 'src/constans/routes';
import SvgTypes from 'src/components/SvgTypes';
import { SHOW_HIDE_SVG } from 'src/constans/svgIcons';
import {useFirstLoginMutation, useLoginMutation, useLogoutMutation} from 'src/hooks/api/useLogin';
import { useGetChatId } from 'src/hooks/chatId';
import { useUserName } from 'src/hooks/userName';
import { checkErrorFromRequest } from 'src/utils/checkErrorFromRequest';
import { useGetFirstLogin } from 'src/hooks/firstLogin';
import { getDeviceType } from 'src/utils/inspectDeviceType';
import { Logo } from 'src/components/common/Logo/Logo';
import { useCompleteForgotPasswordFlow } from 'src/hooks/completeForgotPasswordFlow';

import { getValidationSchema } from './validationSchema';
import * as S from './LoginPage.style';

export const LoginPage = (): JSX.Element => {
  const isMobile = getDeviceType() === 'mobile';
  const [searchParams] = useSearchParams();
  const [error, setError] = useState('');
  const { setCurrentChatId } = useGetChatId();
  const { setUserName } = useUserName();
  const [showHidePassword, changeShowHidePassword] = useState(false);
  const navigate = useNavigate();
  const isFirst = !!searchParams.get('accessToken');
  const token = searchParams.get('accessToken');
  const { mutateAsync: firstLoginRequest } = useFirstLoginMutation();
  const { mutateAsync: loginRequest } = useLoginMutation();
  const { mutateAsync: logoutRequest } = useLogoutMutation();
  const { setIsFirstLogin } = useGetFirstLogin();
  const { setCompleteForgotPassword } = useCompleteForgotPasswordFlow();

  useEffect(() => {
    if (!isFirst) {
      setIsFirstLogin(false);
      setCompleteForgotPassword(false);
      logoutRequest();
    }
  }, [isFirst])

  useEffect(() => {
    setCompleteForgotPassword(false);
  })

  const startSpeakingByClick = () => {
    const speechSynthesis = window.speechSynthesis
    const Utterance = new SpeechSynthesisUtterance()
    Utterance.text = 'i'
    Utterance.volume = 0
    setTimeout(() => speechSynthesis.speak(Utterance), 0)
  }

  return (
    <S.LoginPage isMobile={isMobile}>
      <Logo isMobile={isMobile} />
      <S.LoginPageContent isMobile={isMobile}>
        {isFirst ? <p>Welcome to the Family Portal!<br/> To create your account, please enter your Family Portal username. This was given to you in your account activation email/text. If you can't readily locate the email, it will have the word Family Portal in the subject line.</p> :
          <p>Welcome back!<br/> To log in, please enter your username and password.</p>}
        <Formik
          initialValues={{
            username: '',
            password: '',
          }}
          onSubmit={values => {
            if (isFirst) {
              firstLoginRequest({ username: values.username, access_token: token }).then(res => {
                if (res.status === 200) {
                  setUserName(values.username);
                  setCurrentChatId(res.data.chat_id);
                  setIsFirstLogin(true);
                  return navigate(routes.CREATE_PASSWORD);
                } else {
                  setError(res.data.message);
                }
              });
            } else {
              loginRequest({ username: values.username, password: values.password }).then(res => {
                if (res.status === 200) {
                  setCurrentChatId(res.data.chat_id);
                  setUserName(values.username);
                  startSpeakingByClick()
                  return navigate(routes.PROVIDE_DATA_HANDLER)
                } else {
                  setError(res.data.message);
                  return navigate(checkErrorFromRequest(res.status));
                }
              });
            }
          }}
          validationSchema={getValidationSchema(isFirst)}
        >
          {({ values, handleChange, isValid, dirty }) => (
            <Form>
              <Input
                isLarge={false}
                name={'username'}
                value={values.username}
                labelText={'Username'}
                onChange={handleChange}
                autoComplete='login'
              />
              {!isFirst && (
                <>
                  <S.LoginPageInputWithSvg isMobile={isMobile}>
                    <Input
                      isLarge={false}
                      type={showHidePassword ? 'text' : 'password'}
                      name={'password'}
                      value={values.password}
                      labelText={'Password'}
                      onChange={handleChange}
                      autoComplete='password'
                    />
                    <SvgTypes onClick={() => changeShowHidePassword(!showHidePassword)} type={SHOW_HIDE_SVG} />
                  </S.LoginPageInputWithSvg>
                  <S.ForgotPasswordLink isMobile={isMobile}>
                    <Link className='forgotPassLink' to={routes.RESET_PASSWORD}>Forgot password?</Link>
                  </S.ForgotPasswordLink>
                </>
              )}
              <S.LoginSubmitPage isMobile={isMobile}>
                {!!error && <S.LoginPageErrorMessage>{error}</S.LoginPageErrorMessage>}
                <Button
                  disable={!(isValid && dirty)}
                  type={'submit'}
                >
                  {isFirst ? 'Continue' : 'Log in'}
                </Button>
              </S.LoginSubmitPage>
            </Form>
          )}
        </Formik>
      </S.LoginPageContent>
    </S.LoginPage>
  );
};
