import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { CallToActionsInterface, getPersistentMenu } from 'src/api/persistentMenuRequest';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { useActiveTab } from 'src/hooks/useActiveTab';
import { processingByAttribute } from 'src/utils/processingByAttribute';
import { audioTracks, PostMessagesTypes, Tabs } from 'src/constans/constans';
import { SvgTypes } from 'src/components/SvgTypes/SvgTypes';
import { CHECK_ICON_SVG } from 'src/constans/svgIcons';
import { theme } from 'src/themes/Theme';

import {
  PersistentCustomMenuItem,
  PersistentMenuAskButton,
  PersistentMenuButton,
  PersistentMenuContainer, PersistentMenuFeedbackButton,
  PersistentMenuItem,
  PersistentMenuItemButton,
  PersistentMenuList,
  PersistentMenuPopUp,
} from './PersistentMenu.styles';

interface Props {
  isMobile: boolean
  isMenuOpen: boolean
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>
  widgetSource: MessageEventSource
  logOut: () => void
  updatingAudioQueue: (data: string) => void
  stopPlayAndResetAudioQueue: () => void
}

const PersistentMenu: FC<Props> = ({
  isMobile,
  isMenuOpen,
  setIsMenuOpen,
  widgetSource,
  logOut,
  updatingAudioQueue,
  stopPlayAndResetAudioQueue
}) => {
  const chatId = localStorage.getItem('chatId')
  const [persistentMenu, setPersistentMenu] = useState<CallToActionsInterface[]>([])
  const { activeTab, setActiveTab } = useActiveTab()
  const persistentMenuRef = useClickOutside(() => setIsMenuOpen(false))
  const [isFirstRender, setIsFirstRender] = useState(true)
  const isNeedPersistentMenuUpdate = isFirstRender ? true : isMenuOpen

  useEffect(() => {
    if (isNeedPersistentMenuUpdate) {
      setIsFirstRender(false)
      getPersistentMenu(chatId).then(res => {
        setPersistentMenu(res.callToActions)
      }).catch(() => logOut())
    }
  }, [chatId, isMenuOpen, isNeedPersistentMenuUpdate])

  useEffect(() => {
    if (isMenuOpen) {
      stopPlayAndResetAudioQueue()
      widgetSource.postMessage({
        type: PostMessagesTypes.WIDGET_CANCEL_VOICE_SPEAKING
      }, '*' as WindowPostMessageOptions)

      updatingAudioQueue(audioTracks.persistentMenu)
    }
  }, [isMenuOpen])

  const onClickHandler = (item) => {
    if (!item.enabled) return

    switch (item.type) {
      case 'web_url':
        if (item.enableMessengerExtensions) {
          widgetSource.postMessage({
            type: PostMessagesTypes.WIDGET_OPEN_IFRAME,
            url: item.url
          }, '*' as WindowPostMessageOptions)
        } else {
          const target = item?.url?.endsWith('openInWidgetPage=true') ? '_parent' : '_blank'
          window.open(item.url, target)
        }
        break
      case 'postback':
        widgetSource.postMessage({
          type: PostMessagesTypes.WIDGET_PERSISTENT_MENU_ACTION,
          payload: item.payload,
          title: item.title
        }, '*' as WindowPostMessageOptions)
        break
      case 'custom_redirect':
        widgetSource.postMessage({
          type: PostMessagesTypes.WIDGET_CANCEL_VOICE_SPEAKING
        }, '*' as WindowPostMessageOptions)
        processingByAttribute(item.payload, setActiveTab)
        break
    }

    setIsMenuOpen(false)
  }

  const askButtonClickHandler = () => {
    widgetSource.postMessage({
      type: PostMessagesTypes.WIDGET_PERSISTENT_MENU_ACTION,
      payload: 'FAQ persistent menu : Ask Geda',
      title: 'Ask Alex'
    }, '*' as WindowPostMessageOptions)

    activeTab === Tabs.TAB_2 && setActiveTab(Tabs.TAB_1)
    setIsMenuOpen(false)
  }

  const feedbackButtonClickHandler = () => {
    widgetSource.postMessage({
      type: PostMessagesTypes.WIDGET_PERSISTENT_MENU_ACTION,
      payload: 'FAQ persistent menu : Feedback Score Satisfaction',
      title: 'Provide a feedback'
    }, '*' as WindowPostMessageOptions)

    activeTab === Tabs.TAB_2 && setActiveTab(Tabs.TAB_1)
    setIsMenuOpen(false)
  }

  return (
    <PersistentMenuContainer
      isMobile={isMobile}
      ref={persistentMenuRef}
    >
      <PersistentMenuButton
        isMobile={isMobile}
        onClick={() => setIsMenuOpen(prevState => !prevState)}
      >
        Menu
      </PersistentMenuButton>
      {isMenuOpen && !!persistentMenu.length && (
        <PersistentMenuPopUp
          isMobile={isMobile}
        >
          <PersistentMenuList>
            {persistentMenu.map((item, index) => (
              <PersistentMenuItem key={index} enbled={item.enabled}>
                <PersistentMenuItemButton
                  enbled={item.enabled}
                  onClick={() => onClickHandler(item)}
                >
                  {item.title}
                  {item.completed && <SvgTypes
                    type={CHECK_ICON_SVG}
                    color={theme.colors.white}
                  />}
                </PersistentMenuItemButton>
              </PersistentMenuItem>
            ))}
            <PersistentCustomMenuItem>
              <PersistentMenuAskButton onClick={askButtonClickHandler}>
                Ask Alex
              </PersistentMenuAskButton>
            </PersistentCustomMenuItem>
            <PersistentCustomMenuItem>
              <PersistentMenuFeedbackButton onClick={feedbackButtonClickHandler}>
                Provide a feedback
              </PersistentMenuFeedbackButton>
            </PersistentCustomMenuItem>
          </PersistentMenuList>
        </PersistentMenuPopUp>
      )}
    </PersistentMenuContainer>
  )
}

export default PersistentMenu;
